import { AccountSimilarWins, CompanySecData, PowerRankAccount, PowerRankWorkerResponse, SimilarWinsStatistics, WorkerPreCachedParams } from "types/api";
import { AxiosNode } from "utils/axios";

/*

      "id": "AP_277b429bc909acfb74bbe2e08978bbb1ec6b0954bb58e57f69815d2fd1a2c07b",
      "name": "Tom Carter",
      "email": "tcarter@costar.com",
      "title": "International Finance Director",
      "normalizedTitle": "chief financial officer",
      "linkedInProfile": "http://www.linkedin.com/in/tom-carter-ab8baa5",
      "photo": "https://media.licdn.com/dms/image/C5603AQGmkHF5KkTWSQ/profile-displayphoto-shrink_200_200/0/1579271007334?e=2147483647&v=beta&t=uU7jcLi2q2YtFXbP38GGaq10HZlyw-AfzDr3qYuTVeY",
      "accountName": "CoStar Group",
      "accountId": "0014000001gNf93AAC",
      "isAlreadySaved": false
      */
export interface PersonResult {
  id: string;
  name: string;
  email: string;
  title: string;
  normalizedTitle: string;
  linkedInProfile: string;
  photo: string;
  accountName: string;
  accountId: string;
  isAlreadySaved: boolean;
  isInUserScope?: boolean
}

export type IPersonFromFinderBot = {
  results: PersonResult[];
  invalid: string[];
};

export interface IAccountBusiness {
  name: string;
  description: string;
  website: string;
  industry: string;
  annualRevenue: number;
  numberOfEmployees: number;
}

export type IPeopleContactMetaData = {
  fields: { name: string; label: string; type: string }[];
};

export type PaginatedPayload = {
  accounts?: string[];
  jobs?: string[]
  isIds?: boolean
};

export type PaginatedSavePeoplePayload = {
  data: {
    accountId: string;
    peopleIds: string[]
  }[],
  contactType?: string
}

export interface SimilarWinsWorkerResponse {
  results: AccountSimilarWins[]
  statistics: SimilarWinsStatistics
}

interface CompanySecDataResponse {
  data: CompanySecData;
}

interface WorkerPreCachedParamsPayload {
  data: WorkerPreCachedParams;
}
const aiWorkersService = {
  findPeople: async (
    payload: PaginatedPayload,
    options?: Parameters<typeof AxiosNode.post>[2],
  ) => {
    const { data } = await AxiosNode.post<IPersonFromFinderBot>(
      "/bot/personfinder",
      payload,
      options,
    );
    return data
  },
  findSimilarWins: async (
    payload: PaginatedPayload,
    options?: Parameters<typeof AxiosNode.post>[2],
  ) => {
    const { data } = await AxiosNode.post<SimilarWinsWorkerResponse>(
      "/bot/similarwins",
      payload,
      options,
    );
    return data as SimilarWinsWorkerResponse
  },
  searchAccounts: async (query: string, options?: Parameters<typeof AxiosNode.post>[2]) => {
    const { data } = await AxiosNode.get<{ name: string, domain: string }[]>(`/accounts/search?search=${query}`, options);
    return data;
  },
  powerRank: async (
    payload: PaginatedPayload,
    options?: Parameters<typeof AxiosNode.post>[2],
  ) => {
    const { data } = await AxiosNode.post<PowerRankWorkerResponse>(
      "/bot/accountRank",
      payload,
      options,
    );
    return data
  },
  saveAccountsToTerritory: async (
    payload: PaginatedPayload,
    options?: Parameters<typeof AxiosNode.post>[2],
  ) => {
    const { data } = await AxiosNode.post<PowerRankAccount[]>(
      "/user/territory/add",
      payload,
      options,
    );
    return data;
  },
  getCompanySecFilings: async (ticker: string, options?: Parameters<typeof AxiosNode.get>[1]) => {
    const { data } = await AxiosNode.get<CompanySecDataResponse>(`/company/filing?ticker=${ticker}`, options);
    return data;
  },
  updateCompanySecFilings: async (ticker: string, options?: Parameters<typeof AxiosNode.get>[1]) => {
    const { data } = await AxiosNode.post<CompanySecDataResponse>(`/company/filing?ticker=${ticker}`, options);
    return data;
  },
  savePeopleToAccounts: async (
    payload: PaginatedSavePeoplePayload,
    options?: Parameters<typeof AxiosNode.post>[2],
  ) => {
    const { data } = await AxiosNode.post<any>(
      "/people/create",
      payload,
      options,
    );
    return data;
  },
  workerCachedParams: async (id: string, options?: Parameters<typeof AxiosNode.get>[1]) => {
    const { data } = await AxiosNode.get<WorkerPreCachedParamsPayload>(`/bots/result?id=${id}`, options);
    return data;
  },
  savePeopleToCRM: async (
    payload: PaginatedSavePeoplePayload,
    options?: Parameters<typeof AxiosNode.post>[2],
  ) => {
    try {
      const { data } = await AxiosNode.post<any>(
        "/crm/people",
        payload,
        options,
      );
      return data;
    } catch (error: any) {
      return { error: error.message }
    }
  }
};

export default aiWorkersService;
