import { Box, Stack, Switch, ToggleButton, Typography } from "@mui/material";
import {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useWindowSize } from "react-use";

import { SpreadPagination } from "components/common/Pagination";
import Page from "components/layout/Page";
import UserFeatureAccessWrapper from "components/UserFeatureAccessWrapper";
import { FeatureGroupSet } from "data/userPermissionsMap";
import { useDebounceEffect, useSearchQuery } from "hooks";
import useWindowScreenHeight from "hooks/useWindowScreenHeight";
import useTerritoryMetaDataState from "services/shooks/territoryMeta";
import useTerritoryFilters from "services/shooks/territoryFilters";

import { TerritoryTableColumnWidths } from "types/api";

import { downloadCsvFile } from "utils/file";

import { ActivityFeed, TerritoryPageHeader } from "./components";
import { FilteringResultsDescription } from "./components/common";
import DynamicFilters from "./components/Filters/DynamicFilters";
import { StandAloneAccountDetails } from "./components/FocusPanel/components";
import { TerritoryTable } from "./components/SharedTable";
import {
  FocusWidget,
  calculateRowsCapacity,
} from "./components/utils";
import TableViews from "./components/Views/Views";
import {
  FilterGroupsOpenStateMap,
  convertAccountsToCsvFormat,
  getTableContainerHeight,
  getTableWidth,
} from "./utils";
import { TerritoryFilters } from "types/api/territoryFilters";
import useAccessManager from "hooks/useAccessManager";

export enum ActiveTerritoryScreen {
  Territory,
  Whitespace,
}

export enum TerritoryDisplayMode {
  List = 'list',
  Pages = 'pages'
};

function SharedTerritoryAndWhitespace(props: { targetContent?: ActiveTerritoryScreen }) {
  const { actions, state } = useTerritoryFilters();
  const [territoryMeta, territoryMetaActions] = useTerritoryMetaDataState((store) => [
    store.state,
    store.actions,
  ]);
  const isTerritoryScreen = props.targetContent === ActiveTerritoryScreen.Territory;
  const currentActiveScreen = isTerritoryScreen ? "territory" : "whitespace";
  const { width } = useWindowSize();
  const [windowWidth, setWindowWidth] = useState(width - 100 - 100);
  const [filterGroupsOpenState, setFilterGroupsOpenState] = useState<FilterGroupsOpenStateMap>({} as FilterGroupsOpenStateMap);
  const [fullScreenHeight] = useWindowScreenHeight();
  const onToggleFilter = () => {
    updateSearchParam({ filters: isFilterOpen ? "" : "true" });
  };
  const locationState = useLocation().state
  const { isFreeUser } = useAccessManager();
  const isForFreeUser = isFreeUser()
  const [displayMode, setDisplayMode] = useState<TerritoryDisplayMode>(TerritoryDisplayMode.Pages);

  const onToggleDisplayMode = (event: ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setDisplayMode(checked ? TerritoryDisplayMode.Pages : TerritoryDisplayMode.List)
  };
  const isPageDisplayMode = displayMode === TerritoryDisplayMode.Pages

  const [
    {
      fw: activeFocusWidget,
      filters: isFilterOpen,
      ac_feed: isActivityFeedOpen,
      id: tableViewId,
      itemIndex,
    },
    updateSearchParam,
  ] = useSearchQuery<{
    filters?: string;
    ac_feed?: boolean;
    fw?: FocusWidget;
    itemIndex?: string;
    id?: string;
  }>();

  // @note: legacy code
  const visibleColumns = useMemo(() => {
    const currentDisplayedColumns = isTerritoryScreen
      ? state.territoryColumns
      : state.whitespaceColumns;
    return currentDisplayedColumns;
  }, [isTerritoryScreen, state.territoryColumns, state.whitespaceColumns]);

  const visibleColumnsWidth = useMemo(
    () => getTableWidth({ territoryTableColumnWidths: TerritoryTableColumnWidths, visibleColumns }),
    [JSON.stringify(visibleColumns)],
  );


  useEffect(() => {
    territoryMetaActions.initialize();
  }, []);

  const onPageChange = (page: number) => {
    actions.setPagination({ currentPage: page });
  }

  // update window width when activity feed or filters are toggled
  useEffect(() => {
    const activityFeedWidth = isActivityFeedOpen ? 400 : 100;
    const filtersWidth = isFilterOpen ? 380 : 100;
    setWindowWidth(width - activityFeedWidth - filtersWidth);
  }, [isActivityFeedOpen, isFilterOpen, width, setWindowWidth]);

  const updateResultsFromFilters = async (filters?: TerritoryFilters, abortController?: AbortController) => {
    const updatedResults = await actions.getFilterUpdates(filters!, { signal: abortController?.signal });
    return updatedResults;
  }

  useDebounceEffect(() => {
    const filtersAbortRequest = new AbortController();
    updateResultsFromFilters(state.filters, filtersAbortRequest as AbortController);
    return () => {
      filtersAbortRequest.abort();
    };
  },
    300,
    [JSON.stringify(state.filters), state.pagination?.currentPage, state.pagination?.pageSize],
  );

  // @note: the use of use effect instead of the useDebounceEffect for a reason here!
  useEffect(() => {
    // @note: this allows the use to navigate to the first page when the user change the filters
    actions.setPagination({ currentPage: 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(state.filters)]);

  const pagination = state.pagination || { page: 1, results: 10, total: 0 };

  const isAccountDetailsFocusWidgetOpen = activeFocusWidget === FocusWidget.AccountDetails;
  const [tableContainerHeight, setTableContainerHeight] = useState(
    getTableContainerHeight(fullScreenHeight, isAccountDetailsFocusWidgetOpen),
  );

  useEffect(() => {
    const tableHeight = getTableContainerHeight(fullScreenHeight, isAccountDetailsFocusWidgetOpen);
    setTableContainerHeight(tableHeight);
  }, [isAccountDetailsFocusWidgetOpen, fullScreenHeight]);

  const onDownloadAccounts = async (): Promise<void> => {
    const fileName = `${currentActiveScreen}_${new Date().toISOString().slice(0, 10)}.csv`;
    const allUserAccounts = await actions.getAllUserAccounts(tableViewId!);
    if (allUserAccounts.length === 0) {
      toast.error("Failed to download accounts, please try again later.");
    } else {
      territoryMeta.columns
      const csvContent = convertAccountsToCsvFormat(allUserAccounts, territoryMeta.columns, isForFreeUser);
      downloadCsvFile(fileName, csvContent);
    }
  };

  const onToggleFilterGroupOpenState = (
    groupKey: keyof FilterGroupsOpenStateMap | string,
    isOpen?: boolean,
  ) => {
    setFilterGroupsOpenState({
      ...filterGroupsOpenState,
      [groupKey]: isOpen,
    });
  };

  useEffect(() => {
    actions.updateFiltersView(tableViewId);
  }, [tableViewId, JSON.stringify(state.views)]);

  useEffect(() => {
    actions.listViews();
  }, []);

  const onResetFilters = useCallback(async () => {
    await actions.resetFilters(tableViewId);
  }, [tableViewId]);

  useEffect(() => {
    if (locationState?.filters?.rank) {
      actions.updateFilters({
        Account: {
          insights: {
            suitability: {
              ...state.filters?.Account?.insights?.suitability,
              value: [Number(locationState.filters.rank)],
            }
          }
        }
      });
    }
  }, [locationState]);

  return (
    <Page hasFooter={false}>
      <Box
        sx={{
          background: "#F5F5F5", flex: 1, display: "flex", maxWidth: "100%", overflow: "auto",
        }}
      >
        <Stack flex={1} gap="16px" direction="column" p="24px">
          <TableViews />
          <TerritoryPageHeader
            onToggleFilter={onToggleFilter}
            isFilterOpen={Boolean(isFilterOpen)}
            updateVisibleColumns={territoryMetaActions.updateVisibleColumns}
            columnsMeta={territoryMeta.columns}
            isTerritoryScreen={isTerritoryScreen}
            onDownloadAccounts={onDownloadAccounts}
          />
          <div style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            flexDirection: "row",
            width: "100%",
          }}
          />
          <Box
            flex={1}
            display="flex"
            borderRadius="8px"
            sx={{ bgcolor: "#FFFF", overflow: "hidden", maxHeight: tableContainerHeight, zIndex: 2 }}
          >
            {
              (Boolean(isFilterOpen) && territoryMeta) && (
                <DynamicFilters
                  key={tableViewId}
                  onHideFilters={onToggleFilter}
                  onResetFilters={onResetFilters}
                  filtersMetaData={territoryMeta.filters}
                  filterGroupsOpenMap={filterGroupsOpenState}
                  onToggleFilterGroupOpenState={onToggleFilterGroupOpenState}
                />
              )
            }
            {/* @note: too naive approach but gives good flexibility passing different props */}
            <TerritoryTable
              columnsMeta={territoryMeta.columns}
              tableMaxWidth={windowWidth}
              visibleColumnsWidth={visibleColumnsWidth}
              displayMode={displayMode}
              tableContainerHeight={tableContainerHeight}
            />
          </Box>
          <Stack direction="row" justifyContent="space-between" style={{ flexWrap: "wrap", zIndex: 3, gap: '20px' }}>
            <Stack direction={'row'} alignItems='centers' justifyContent='center' sx={{ gap: '10px', minHeight: '36px' }}>
              <FilteringResultsDescription
                total={pagination.total}
                pageSize={pagination.pageSize}
                page={pagination.currentPage}
                results={pagination.results}
              />
            </Stack>
            {isPageDisplayMode && (
              <SpreadPagination
                page={pagination.currentPage}
                total={pagination.total}
                pageSize={pagination.pageSize}
                onPageChange={onPageChange}
              />
            )}
          </Stack>
          {isAccountDetailsFocusWidgetOpen && (
            <Stack minHeight="605px">
              <StandAloneAccountDetails />
            </Stack>
          )}
        </Stack>
        <ActivityFeed />
      </Box>
    </Page>
  );
}

export default function (props: { targetContent?: ActiveTerritoryScreen }) {
  return (
    <UserFeatureAccessWrapper groupName={FeatureGroupSet.TERRITORY_PAGE} accessDeniedComponent={<Box>Access Denied</Box>}>
      <SharedTerritoryAndWhitespace {...props} />
    </UserFeatureAccessWrapper>
  );
}
