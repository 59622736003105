import { useAccountSearch } from 'pages/AIWorkers/workers/PeopleFinder/hooks'
import React, { useMemo } from 'react'
import InputFilter from '../DynamicFilterComps/InputFilter'

type Props = {
  valuePath: string;
  label: string;
  hasActiveFilters: boolean;
  values: string[];
  options: string[];
}
const SimilarWinsFilter = (props: Props) => {
  const { accounts, loading, onSearchKeyUpdates } = useAccountSearch()
  const { valuePath, label, values } = props;

  const options = accounts.map(({ name }) => name)
  return (
    <InputFilter
      valuePath={valuePath}
      label={label}
      hasActiveFilters
      values={values}
      options={options}
      onSearch={onSearchKeyUpdates}
      isLoading={loading}
    />
  )
}

export default SimilarWinsFilter;